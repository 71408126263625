import { Box, Divider, Flex, Stack } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type { FC } from 'react'
import { useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import useGetObjectsByProperties from '@app/hooks/useGetObjectsByProperties'
import ChatDrawerHeader from '@app/pages/maps/components/aiChat/components/chatDrawerHeader'
import MessageContent from '@app/pages/maps/components/aiChat/components/messageContent'
import MessageGroup from '@app/pages/maps/components/aiChat/components/messageGroup'
import PromptForm from '@app/pages/maps/components/aiChat/components/promptForm'
import type { MapDomainMessage } from '@app/types'
import type { AiAssistantRoleEnum, PipelineEnum } from '@graphql/types'

type Props = {
  pipelineId: PipelineEnum
  chatId?: string
  strategyId?: string
}

type MessageGrouping = [
  string,
  string,
  AiAssistantRoleEnum,
  Pick<MapDomainMessage, 'id' | 'classType' | 'content' | 'role' | 'userId' | 'createdAt'>[]
]
const Show: FC<Props> = ({ pipelineId, chatId: propChatId = null, strategyId: propStrategyId = null }) => {
  const { chatId: paramChatId, strategyId: paramStrategyId } = useParams()
  const chatId = propChatId || paramChatId
  const strategyId = propStrategyId || paramStrategyId
  const storeMessages = useGetObjectsByProperties('message', { chatId })
  const messages = useMemo(
    () => sortBy(storeMessages, 'createdAt').filter((message) => message.content),
    [storeMessages]
  )
  const scrollRef = useRef(null)

  // condense messages from the same user
  const messageGroups = useMemo(
    () =>
      messages.reduce<MessageGrouping[]>((acc, message) => {
        const [lastKey, lastUserId, lastRole, lastMessages] = acc[acc.length - 1] || []

        if (
          lastMessages &&
          ((lastRole === 'user' && message.role === 'user' && lastUserId === message.userId) ||
            (lastRole === 'assistant' && message.role === 'assistant'))
        ) {
          acc[acc.length - 1] = [lastKey, lastUserId, lastRole, [...lastMessages, message]]
        } else {
          acc.push([message.id, message.userId, message.role, [message]])
        }

        return acc
      }, []),
    [messages]
  )

  // scroll to the bottom of the list when the messages list changes
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: scrollRef.current.scrollHeight, behavior: 'smooth' })
    }
  }, [messages.length, scrollRef?.current?.scrollHeight])

  return (
    <Flex pos="relative" direction="column" overflow="hidden" h="100%" bg="bg.canvas">
      <ChatDrawerHeader />
      <Flex ref={scrollRef} overflowY="auto" pt="0" pb="40" grow={1}>
        <Stack
          w="100%"
          mx="auto"
          px={4}
          divider={
            <Box ml="14!">
              <Divider />
            </Box>
          }
          spacing="10"
        >
          {messageGroups?.map(([groupKey, userId, role, contents]) => (
            <MessageGroup
              key={groupKey}
              userId={userId}
              role={role}
              messages={contents.map((message) => [
                message.id,
                message,
                <MessageContent key={message.id} message={message} />
              ])}
            />
          ))}
        </Stack>
      </Flex>

      <Flex mx="4" py="4" bgGradient="linear(to-t, bg.canvas 80%, rgba(0,0,0,0))" grow={0} insetX="0">
        <PromptForm pipelineId={pipelineId} chatId={chatId} strategyId={strategyId} />
      </Flex>
    </Flex>
  )
}

export default Show
