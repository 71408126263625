import { Button, HStack, Kbd, Text, Tooltip } from '@chakra-ui/react'
import { FiSend } from 'react-icons/fi'

const SubmitButton = ({ isDisabled }) => {
  const label = isDisabled ? (
    'Waiting'
  ) : (
    <HStack>
      <Text>Submit</Text>
      <Kbd color="black">ctrl/cmd + enter</Kbd>
    </HStack>
  )

  return (
    <Tooltip aria-label={isDisabled ? 'Waiting' : 'Submit'} label={label}>
      <Button colorScheme="gray" isDisabled={isDisabled} isLoading={isDisabled} size="sm" type="submit" variant="text">
        <FiSend />
      </Button>
    </Tooltip>
  )
}

export default SubmitButton
